
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'kt-drawer-institute-target',
  components: { Field },

  data() {
    return {
      course_details: {},
      load: false,
      course_id: '',
      trainee_target: '',
      institute_id: '',
      entity_id: '',
      tranche: '',
      target_id: '',
    };
  },
  async created() {
    this.emitter.on('institute_target_add', async (data) => {
      // console.log(data);
      this.course_details = data;
      this.course_id = data.course_info_id;
      this.institute_id = data?.institute_info_id;
      this.trainee_target = data.total_target_trainee;

      // this.institute_id=data.institute_id;
      this.entity_id = data?.entity_info_id;
      this.target_id = '';
      if (data.institute_target_details) {
        this.target_id = data?.institute_target_details?.id;
      }

      this.tranche = data?.tranche_id;
      if (!this.trainee_target) {
        this.trainee_target =
          data.institute_target_details.total_target_trainee;
      } else {
        this.trainee_target = data.total_target_trainee;
      }
      await this.getUpdateLog(this.target_id);

      // api
    });
  },
  methods: {
    async getUpdateLog(id) {
      this.load = true;
      await ApiService.get(
        this.VUE_APP_PENDING_LOG_TARGET_LIST_API + '?id=' + id
      )
        .then((response) => {
          this.load = false;
          console.log(response);
          let data = response.data.data;
          // console.log(
          //   'updatelog data ' +
          //     JSON.parse(data.form_data) +
          //     'trainee_target' +
          //     this.trainee_target
          // );
          if (data) {
            this.trainee_target = JSON.parse(data.form_data);
          }
        })
        .catch((response) => {
          this.load = false;
          console.log(response);
        });
    },
    async formSubmit() {
      // let data = {
      //   trainee_target: this.trainee_target,
      //   course_id: this.course_id,
      // };
      let data = new FormData();
      data.set('trainee_target', this.trainee_target);
      data.set('entity_id', this.entity_id);
      data.set('institute_id', this.institute_id);
      data.set('tranche_id', this.tranche);
      data.set('course_id', this.course_id);
      data.set('target_id', this.target_id);
      let user_id = VueCookieNext.getCookie('_seip_user');
      let role_id = VueCookieNext.getCookie('_seip_role_id');

      data.set('user_id', user_id.id);
      data.set('role_id', role_id);
      await ApiService.post('institutetarget/set', data)
        .then((response) => {
          this.load = false;
          if (response.data.status == 'success') {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.trainee_target = '';
              this.course_id = '';
              DrawerComponent?.hideAll();
              this.emitter.emit('course-updated', true);
            });
          } else {
            Swal.fire({
              title: 'Error!',
              text: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            }).then(() => {
              // this.trainee_target = '';
              // this.course_id = '';
            });
          }
        })
        .catch(({ response }) => {
          this.load = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
