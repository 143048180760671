
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import TargetDrawer from '@/layout/header/partials/trainning_institute/InstituteTargetDrawer.vue';
//import TargetModal from "@/components/modals/forms/institute/InstituteTarget.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-institute-target-list',
  components: {
    Form,
    Field,
    Datatable,
    TargetDrawer,
  },
  data() {
    return {
      batch: {
        entity_id: '' as any,
        tranche_id: '' as any,
        institute_id: '',
        course_info_id: '',
      } as any,
      entity_type: '' as any,
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [] as any,
      course_Info: [],
      courseList: [],
      courseData: [],
      tranches_info: [],
      batchInfo: [],
      instituteList: [],
      componentKey: 0,
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
        },
        {
          name: 'Institute',
          key: 'institute',
          sortable: true,
        },
        {
          name: 'Target Trainee',
          key: 'target_trainee',
          sortable: true,
        },
        {
          name: 'Batch Size',
          key: 'batch_size',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          name: '# of Batches',
          key: 'of_batch',
          sortable: true,
        },
      ],
      institute_name: '',
      institute_id: '',
      target: 0,
      data: {},
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      load: false,
    };
  },
  async created() {
    this.emitter.on('course-updated', async () => {
      await this.courseInfo();
    });
    await this.getEntityInfos();
    await this.getTranches();
    this.entity_type = VueCookieNext.getCookie('_seip_entity_type')
  },
  methods: {
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } else {
        entity_id = this.associationData;
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_id', this.batch.institute_id);

      await ApiService.post('institutetarget/institutetargetlistpdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } else {
        entity_id = this.associationData;
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_id', this.batch.institute_id);

      axios
        .post(`${this.VUE_APP_API_URL}/api/institutetarget/institutetargetlistprint`, formData)
        .then(response => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } else {
        entity_id = this.associationData;
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_id', this.batch.institute_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/institutetarget/institutetargetlistexport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'targetlist.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },

   
    async getEntityInfos() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
        this.entity_type = VueCookieNext.getCookie('_seip_entity_type');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          console.log(response);
          this.tranches_info = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.load = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }

      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
         this.batch.entity_id +
        '&institute_info_id=' +
        institute_info_id +
        '&course_info_id=' +
        this.batch.course_info_id
      )
        .then((response) => {
          this.instituteList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseInfo() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } else {
        entity_id = this.batch.entity_id;
      }
      console.log(entity_id);
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          "?entity_id=" +
          entity_id +
          "&tranche=" +
          this.batch.tranche_id +
          "&institute_info_id=" +
          this.batch.institute_id
      )
        .then((response) => {
          this.courseData = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async courseInfo() {
      let entity_id = '';
       let training_institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } else {
        entity_id = this.batch.entity_id;
      }
       if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        training_institute_id = VueCookieNext.getCookie('_institute_info_id');
      } else {
        training_institute_id = this.batch.institute_id;
      }
      if (this.batch.tranche_id && this.batch.entity_id) {
        this.load = true;
        await ApiService.get(
          'institutetarget/all-list?tranche_id=' +
          this.batch.tranche_id +
          '&entity_id=' +
           entity_id +
          '&course_info_id=' +
          this.batch.course_info_id +
          '&training_institute_id=' +
         training_institute_id + '&active_status=1'
        )
          .then((response) => {
            this.courseList = response.data.data;
            this.componentKey += 1;
            this.showCourseNotice = true;
            this.load = false;
          })
          .catch((response) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please Select Tranche and Training Partner',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

   async targetSet(target_info, index){
      let input = "input[name='target_["+index+"]']";
      const inputElement = document.querySelector(input) as any;
      const inputValue = inputElement ? inputElement.value : null;


      let data = new FormData();
      data.set('trainee_target', inputValue);
      data.set('entity_id', target_info.entity_id);
      data.set('institute_id', target_info.institute_id);
      data.set('tranche_id',  target_info.tranche);
      data.set('course_id',  target_info.id);
      data.set('target_id', target_info.target_id);

      let user_id = VueCookieNext.getCookie('_seip_user');
      let role_id = VueCookieNext.getCookie('_seip_role_id');

      data.set('user_id', user_id.id);
      data.set('role_id', role_id);
      if(inputValue < 0 ){
          Swal.fire({
            title: 'Warning!',
            text: 'Target can be negative.',
            icon: 'warning',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
          })
      }else{
      await ApiService.post('institutetarget/set', data)
        .then((response) => {
          this.load = false;
          if (response.data.status == 'success') {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.emitter.emit('course-updated', true);
            });
          } else {
            Swal.fire({
              title: 'Error!',
              text: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            }).then(() => {
              // this.trainee_target = '';
              // this.course_id = '';
            });
          }
        })
        .catch(({ response }) => {
          this.load = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });

      }
      
    },

    edit(data) {
      // data.institute_id=this.institute_id;
      // data['institute_id']=this.institute_id;
      this.emitter.emit('institute_target_add', data);
    },

    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
